@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar {
    scrollbar-color: theme(colors.black.48) transparent;
    scrollbar-width: thin;
  }

  .scrollbar::-webkit-scrollbar {
    @apply w-smaller mr-[20px];
  }

  .scrollbar::-webkit-scrollbar-track {
    @apply bg-transparent rounded-large;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-black-48 rounded-large;
  }

  .corner-compensation {
    @apply w-[calc(100%+8px)] -mx-smallest;
  }
}
